<template>
    <v-container grid-list-lg class="mt-7 cart">
        <v-layout row wrap v-if="cartProducts.length">
            <v-flex xs12 sm8>
                <v-simple-table v-if="$vuetify.breakpoint.smAndUp">
                    <template v-slot:default>
                        <thead>
                        <tr class="header-table">
                            <th></th>
                            <th colspan="2" class="text-left">Produkt</th>
                            <th class="text-center">Cena</th>
                            <th class="text-center">Množství</th>
                            <th class="text-right">Mezisoučet</th>
                        </tr>
                        </thead>
                        <tbody v-if="cartProducts.length && cartProducts[0] !== null">
                        <tr v-for="item in cartProducts" :key="item.productId" class="grey--text">
                            <td>
                                <v-btn icon @click="removeFromCart(item)">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                            </td>
                            <td style="max-width: 80px">
                              <v-avatar class="mt-1">
                                <v-img max-width="80px" :src="getProductImage(item.imageUrl)"></v-img>
                              </v-avatar>
                            </td>
                            <td>
                                <strong class="black--text">{{ item.name }}</strong>
                            </td>
                            <td class="text-center">{{formatThousands(item.amountWithVat)}} Kč</td>
                            <td class="text-center">
                                <vue-numeric-input v-model="item.qty" :min="1" :max="10000" align="center" size="100px" style="height: 36px" class="ma-0" @input="changeValue(item)"></vue-numeric-input>
                            </td>
                            <td class="text-right primary--text item-price"><strong>{{ formatThousands(getPrice(item.amountWithVat, item.qty)) }} Kč</strong></td>
                        </tr>

                        </tbody>
                    </template>
                </v-simple-table>
                <div v-else class="mobile-table">
                    <v-layout v-for="item in cartProducts" :key="item.productId">
                        <v-flex xs3>
                            <v-img max-width="80px" :src="getProductImage(item.imageUrl)"></v-img>
                        </v-flex>
                        <v-flex xs9>
                            <div class="d-flex justify-space-between align-center">
                                <span><strong class="black--text">{{ item.name }}</strong></span>
                                <span>
                                    <v-btn icon @click="removeFromCart(item)">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                                </span>
                            </div>
                            <div class="d-flex justify-space-between align-center my-2">
                                <span class="text-uppercase">Cena</span>
                                <span>{{formatThousands(item.amountWithVat)}} Kč</span>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex justify-space-between align-center my-2">
                                <span class="text-uppercase">Množství</span>
                                <span>
                                    <vue-numeric-input v-model="item.qty" :min="1" :max="10" align="center" size="100px" style="height: 36px" class="ma-0" @input="changeValue(item)"></vue-numeric-input>
                                </span>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex justify-space-between align-center my-2">
                                <span class="text-uppercase">Mezisoučet</span>
                                <span class="primary--text">{{ formatThousands(getPrice(item.amountWithVat, item.qty)) }} Kč</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
            <v-flex xs12 sm4>
                <v-card outlined>
                    <v-card-title class="text-uppercase black--text">Celkem k platbě</v-card-title>
                    <v-card-text>
                        <p class="d-flex justify-space-between">
                            <span>Mezisoučet</span>
                            <span>{{ formatThousands(total) }} Kč</span>
                        </p>
                        <p class="d-flex justify-space-between" v-if="user && user.discount">
                            <span>Sleva</span>
                            <span>{{ discount }} Kč</span>
                        </p>
                        <v-divider></v-divider>
                        <p class="d-flex justify-space-between order-total mt-4">
                            <span class="black--text">Cena celkem</span>
                            <span v-if="user && discount" class="primary--text amount">{{ formatThousands(totalDiscount) }} Kč</span>
                            <span v-else class="primary--text amount">{{ formatThousands(total) }} Kč</span>
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block tile depressed color="primary" to="/checkout">Přejít k pokladně</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout v-else>
            <v-flex xs12 text-center>
                <v-icon class="empty-cart">
                    mdi-cart-remove
                </v-icon>
                <h1 class="my-4">Váš košík je prázdný</h1>
                <v-btn color="primary" tile depressed large to="/">Zpět do katalogu</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import VueNumericInput from 'vue-numeric-input'
    import {getProductImage, formatThousands} from '../Helper'

    export default {
        name: 'Cart',
        mixins: [getProductImage, formatThousands],
        components: {
            VueNumericInput
        },
        computed: {
            ...mapGetters([
                'cartProducts',
                'user'
            ]),
            total () {
                if (this.cartProducts[0] === null) return null;
                return this.cartProducts.reduce((total, p) => {
                    return total + p.amountWithVat * p.qty
                }, 0)
            },
            totalDiscount() {
                if (this.cartProducts[0] === null) return null;
                let total = this.cartProducts.reduce((total, p) => {
                    return total + p.amountWithVat * p.qty
                }, 0);

                if (this.discount) {
                    return parseInt(total) - this.discount;
                }

                return total;
            },
            discount() {
                if (this.user && this.user.discount) {
                    return Math.round(this.total *  this.user.discount);
                }

                return false;
            }
        },
        methods: {
            ...mapActions([
                'changeQuantity',
                'removeFromCart'
            ]),
            getPrice(price, quantity) {
                return quantity * price;
            },
            changeValue(product) {
                this.changeQuantity(product);
            }
        }
    }
</script>

<style lang="scss">
    .cart {
        .mobile-table {
            font-size: 14px;
        }
        .item-price {
            font-size: 16px;
        }
        .order-total {
            font-size: 18px;
            .amount {
                font-size: 22px;
            }
        }
        .vue-numeric-input {
            input {
                outline: none;
                height: 100%;
            }
            .btn {
                background: transparent;
                box-shadow: none;
                border: none;
                &:hover {
                    background: #fa3600;
                }
            }
        }
        .empty-cart {
            font-size: 86px !important;
            color: rgba(135,135,135,.15) !important;
        }
    }
</style>

<style scoped lang="scss">
    @media (min-width: 1264px) {
        .container {
            max-width: 1185px;
            &.container--fluid {
                max-width: 100%;
            }
        }
    }
</style>
